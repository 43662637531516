import React, { useState, useContext } from 'react';
import { Container, WrapperMain, ContainerSecondary } from './styles';
import Toggle from '../../../../components/Toggle';
import { ContextReducer } from '../../../../reducer/AdminReducer';
import Wrapper from '../Wrapper';
import BoxMessage from '../BoxMessage';
import { Button } from '../../styles';
import { ButtonLoading } from '../../styles';

const BoxProduct = ({ config, setConfig, handleSubmitForm, saveButton, setSaveButton }) => {
    const { state } = useContext(ContextReducer);
    const [ open, setOpen ] = useState(false);
    const [enableEmail, setEnableEmail] = useState(state?.config?.ecommerce?.transactionEmailConfig?.enableEmail);
    const [statusButton, setStatusButton ] = useState({ loading: false, done: false});

    const _availableDays = [ '7', '14', '21', '28', '35', '42', '49', '56'];
    const _availableWeeks = ['7', '14', '21', '28', '35', '42'];
    const _availableTries = [ '4', '8', '12', '16', '20', '24'];

    const handleSingularOrPluralBounce = (item) => {
        return (item === '1' ? state?.i18n?.configuration?.newsletter?.placeholder9 : state?.i18n?.configuration?.newsletter?.placeholder8);
    }

    const handleUpdateConfig = (name, value) => {
        setConfig({...config, [name]: value})
    }

    const handleProductState = (value, event) => {
        event.stopPropagation();

        setSaveButton({...saveButton, product: true});
        setEnableEmail(value);
        handleUpdateConfig('enableEmail', value);
    }

    const handlePromise = async () => {
        return new Promise((resolve) => {
            handleSubmitForm()
            resolve();
        })
    }
    const handleClickSave = async () => {
        setStatusButton({...statusButton, loading: true})
        await handlePromise();
        setStatusButton({...statusButton, done: true, loading: false});
        setTimeout(() => {
            setStatusButton({...statusButton, done: false});
        }, 3000);
        setSaveButton({...saveButton, product: false});
    }

    const renderNameButton = () => {
        if (statusButton.loading) {
            return state?.i18n?.buttonSave.loading
        } else if (statusButton.done) {
            return state?.i18n?.buttonSave.done
        } else {
            return state?.i18n?.buttonSave.save
        }
    }

    return (
        <>
            <Container open={open}>
                <WrapperMain
                    onClick={() => setOpen((prevState) => !prevState)}
                    open={open}
                >
                    <span>{state.i18n.configuration.newsletter.collectProduct}</span>
                    <Toggle
                        handleClick={(event) => handleProductState(enableEmail === true ? false : true, event)}
                        from={'newsletterConfig'}
                        active={enableEmail}
                    />
                </WrapperMain>
                {open &&
                    <ContainerSecondary>
                        <Wrapper
                            name={'sendEmailInDays'}
                            defaultChecked={state?.config?.ecommerce?.transactionEmailConfig?.sendEmailInDays}
                            placeholder={state?.i18n?.configuration?.newsletter?.placeholder5}
                            array={_availableDays}
                            title={state?.i18n?.configuration?.newsletter?.title2}
                            from={'emailsGeneral'}
                        />
                        <Wrapper
                            name={'bounceMinDays'}
                            defaultChecked={state?.config?.ecommerce?.bounceMinDays}
                            placeHolderFunc={handleSingularOrPluralBounce}
                            array={_availableWeeks}
                            title={state?.i18n?.configuration?.newsletter?.title18}
                            from={'weeks'}
                        />
                        <Wrapper
                            from={'maxAttempts'}
                            name={'maxAttempts'}
                            defaultChecked={state?.config?.ecommerce?.maxAttempts}
                            array={_availableTries}
                            title={state?.i18n?.configuration?.newsletter?.title19}
                        />
                        <BoxMessage config={config} />
                    </ContainerSecondary>
                }
            </Container>
            {(saveButton['product'] || (statusButton.loading || statusButton.done)) &&
                <Button
                    from={'newsletter'}
                    type="button"
                    done={statusButton.done}
                    loading={statusButton.loading}
                    disabled={(statusButton.loading || statusButton.done)}
                    onClick={() => handleClickSave()}
                >
                    <strong>{renderNameButton()}</strong>
                    {statusButton.loading && <ButtonLoading/>}
                </Button>
            }
        </>
    );
};

export default BoxProduct;
