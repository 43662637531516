import React, {useContext, useEffect, useMemo, useState, useRef } from 'react';

import {ListHeaderItem} from '../../Containers/styles';
import {Actions, Tooltip} from './styles';

import {BsCheckLg} from 'react-icons/bs';
import {GrClose} from 'react-icons/gr';

import {changeReview, getHistoric, setStatusButtonAction} from "../../actions/ReviewsAction";
import {ContextReducer} from "../../reducer/AdminReducer";
import { ReactComponent as TrashIcon } from '../../assets/Icon-trash-action.svg';

const ButtonsActions = (props) => {
    const {state, dispatch} = useContext(ContextReducer);
    const [activeId, setActiveId] = useState(null);
    const firstRenderRef = useRef(true);

    const thisItemOnSelect = state?.selecteds?.find(item => (item?.id === props?.valueId) && (item?.author === props?.item?.author));

    const handleFromToStatusNumber = (status) => {
        switch (status) {
            case 'PENDING':
                setActiveId(null);
                break;
            case 'APPROVED':
                setActiveId(1);
                break;
            case 'REJECTED':
                setActiveId(2);
                break;
            case 'DELETED':
                setActiveId(3);
            break
            default:
            setActiveId(null);
        }
    }

    useEffect(() => {
        handleFromToStatusNumber(props.status);
    }, [props.status])

    useEffect(() => {
        if(props.from !== 'items') {
            handleFromToStatusNumber(state.statusSelectedAll || state.filters.status);
        } else {
            if(thisItemOnSelect) {
                handleFromToStatusNumber(state.statusSelectedAll);
            }
        }
    },[state.statusSelectedAll, state.filters.status])

    useEffect(() => {
        if(firstRenderRef.current){
            firstRenderRef.current = false;
            return
        } else {
            if(state.statusButtonAction === 'accessNotAllowed'){
                handleFromToStatusNumber(props.status);
                setStatusButtonAction(dispatch, '');
            }
        }
    }, [ state.statusButtonAction ])


       const renderAreas = (type) => {
        switch (type) {
            case 'reviews':
                return 'review'
            case 'comments':
                return 'comment'
            case 'answers':
                return 'answer'
            case 'questions':
                return 'question'
            case 'nps':
                return 'nps'
            default:
                return ''
        }
    }


    const handleClickAction = (type, id) => {
        if(id === activeId) {
            if(props.from === 'items') {
                setActiveId(null);
            }

            if(props.from !== 'items') {
                dispatch({
                    type: 'SET_STATUS_SELECTALL',
                    payload: 'PENDING'
                })
            }

            type = 'PENDING'
        } else {
            setActiveId(id);
        }

        let _type = 'PENDING';

        if (props.status !== type || props.status === type) {
            _type = type;
        }

        if (props.fixed) {
            changeReview(state.actualRoute, dispatch, props.checkeds, 'status', type, state.filters.status, state?.userInfo?.token, state?.userInfo?.ecommerce, state?.actualArea, state?.filters, props.allItems && true);
        } else {
            changeReview(props.type, dispatch, [
                {
                    "id": props.item.id,
                    "author": props.item.author
                }
            ], 'status', _type, state.filters.status, state?.userInfo?.token, state?.userInfo?.ecommerce, state?.actualArea, state?.filters);
            getHistoric(dispatch, state?.userInfo?.ecommerce, state?.userInfo?.token, props.item.id, props.item.author, renderAreas(props.type), props.idx);
        }
    }


    const handleActive = (id) => {
        if(activeId === id) {
            return true
        }
    }

    return useMemo(() => {
        return (
            <>
                <ListHeaderItem
                    type='buttons'
                    align='center'
                    fx={props.fixed}
                    active={state?.selecteds.length > 1}
                    isNpsRoute={state.actualRoute === 'nps'}
                    allItems={props.allItems}
                >
                    <Actions
                        actions='arquivar'
                        act={handleActive(3)}
                        allItems={props.allItems}
                        onClick={() => handleClickAction('DELETED', 3)}
                    >
                        <TrashIcon />
                        <Tooltip
                            actions='arquivar'
                            allItems={props.allItems}
                        >
                            {state?.i18n?.tagsAction.DELETED}
                        </Tooltip>
                    </Actions>
                    <Actions
                        border={(props?.item?.status === 'PENDING' && props?.item?.sentiments?.sentiment === 'negative') && 'dotted'}
                        actions='naopublicar'
                        act={handleActive(2)}
                        allItems={props.allItems}
                        onClick={() => handleClickAction('REJECTED', 2)}
                    >
                        <GrClose/>
                        <Tooltip
                            actions='naopublicar'
                            allItems={props.allItems}
                        >
                            {state?.i18n?.tagsAction.REJECTED}
                        </Tooltip>
                    </Actions>
                    <Actions
                        border={(props?.item?.status === 'PENDING' && props?.item?.sentiments?.sentiment !== 'negative') && 'dotted'}
                        actions='publicar'
                        act={handleActive(1)}
                        allItems={props.allItems}
                        onClick={() => handleClickAction('APPROVED', 1)}
                    >
                        <BsCheckLg/>
                        <Tooltip
                            actions='publicar'
                            allItems={props.allItems}
                        >
                            {state?.i18n?.tagsAction.APPROVED}
                        </Tooltip>
                    </Actions>
                </ListHeaderItem>
            </>
        );
    }, [state.selecteds, state.reviews, activeId, state?.actualArea, state.historic, props.allItems])
}

export default ButtonsActions;
